/*폼 공통*/

/* input 스타일 */
input[type="text"], input[type="password"], input[type="date"] {
    width: 100%;
    height: 52px;
    line-height: 1;
    padding: 10px 14px;
    font-size: 18px;
    font-weight:400;
    color:#333;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    box-sizing: border-box;
}

input::placeholder {
    font-size: 18px;
    color: #8d8d8d;
    opacity: 1;
}

input[type="text"].wrong {
    border: 1px solid #DA2128;
}

input[type="date"] {
    width:154px;
    height: 34px;
    font-size:14px;
}

input[type="date"]::-webkit-datetime-edit-text, input[type="date"]::-webkit-datetime-edit-month-field, input[type="date"]::-webkit-datetime-edit-day-field, input[type="date"]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    color:#84888D;
}

/* button 스타일 */
button{
    background: inherit;
    border:none;
    box-shadow:none;
    border-radius:10px;
    padding:0;
    overflow:visible;
    cursor:pointer
}
/* button 스타일 */

/* radio 스타일 */
.radio {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
    margin-left: 9px;
}

.radio input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}

.radio input[type="radio"] + label {
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: #333;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.radio input[type="radio"] + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 100%;
}

.pay-check .radio input[type="radio"] + label:before {
    top: 2px;
    border-radius:4px;
}

.radio input[type="radio"]:checked + label:before {
    width:18px;
    height:18px;
    background: #5A5A5A;
    border: 1px solid #5A5A5A;
}

.radio input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 100%;
}
.pay-check .radio input[type="radio"]:checked + label:after {
    background: url("../../resources/admin/icon/check.svg") #5A5A5A no-repeat center/10px 10px;
    border-color: #5A5A5A;
    top:7px;
    left:5px;
}

/* check 스타일 */
.check{
    display: block;
}

.check input[type="checkbox"] {
    display: none;
}

.check input[type="checkbox"] + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #5A5A5A;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    cursor: pointer;
}

.check:after {
    display: block;
    clear: both;
    content: "";
}

.check input[type="checkbox"]:checked + label {
    background: url("../../resources/admin/icon/check.svg") #5A5A5A no-repeat center/10px 10px;
    border-color: #5A5A5A;
}

.check {
    position: relative;
}

.check input[type="checkbox"] + label span {
    position: absolute;
    top: -6px;
    left: 30px;
    display: block;
    font-size: 16px;
    color: #5A5A5A;
}

/* select 스타일 */
select {
    width: 250px; /* 원하는 너비설정 */
    padding: 10px 14px;
    font-size: 16px;
    color: #5A5A5A;
    border: 1px solid #dfdfdf;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../resources/admin/icon/droparrow.svg") no-repeat 90% 50%;
}

select::-ms-expand {
    display: none;
}

/* textarea 스타일 */
.textbox {
    width: 100%;
    height: 110px;
    padding: 16px;
    font-size: 18px;
    line-height:1.4;
    color: #000;
    border: solid 1px #dfdfdf;
    border-radius: 4px;
    resize: vertical;
    vertical-align: middle;
}

textarea::placeholder {
    font-family: inherit;
    font-size: 18px;
    line-height:1.3;
    color: #8D8D8D;
    opacity: 1;
}

/* file 스타일 */
.filebox label {
    display: inline-block;
    width: 100px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background-color: #5A5A5A;
    border-radius: 3px;
    cursor: pointer;
}

.filebox input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
/*폼 공통*/

