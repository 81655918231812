#basic-modal {
    /*display: none;*/
    z-index: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.modal {
    width:500px;
    padding:104px 0 94px;
    background:#fff;
    border:1px solid #DFDFDF;
    border-radius:4px;
    position:absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.modal-btn {
    display:inline-block;
    margin:0 5px;
    width:130px;
    height:46px;
    border-radius: 4px;
    font-size:18px;
    font-weight:500;
    text-align: center;
}
#modal-close {
    position:absolute;
    top:18px;
    right:18px;
    cursor: pointer;
}
.btn-ok {
    background:#00934B;
    color:#fff;
}
.btn-edit {
    background:#fff;
    border:1px solid #00934b;
    color:#00934b;
}
.btn-cancel {
    background:#fff;
    border:1px solid #656565;
    color:#656565;
}
.btn-wrap {
    text-align:center;
}
.fail-modal {
    padding:90px 0;
    text-align: center;
}
.fail-modal > span {
    display: block;
    font-size:18px;
    text-align: center;
    color:#000;
    margin-bottom: 28px;
}
.fail-modal .btn-ok {
    margin:0 auto;
}
.info-modal {
    width:808px;
    padding: 64px 64px 64px;
}
.info-modal h1 {
    font-size:30px;
    font-weight:500;
    color:#000;
    text-align: left;
}
.modal-in {
    width:100%;
    margin:40px auto 60px;
}
.modal-in li {
    margin-bottom:10px;
}
.modal-in li:last-child {
    margin-bottom:0;
}
.modal-in li > div {
    width:30%;
    float:left;
    padding-top: 14px;
    font-size:18px;
    color:#000;
}
.modal-in li > input {
    width:68%;
    float:right;
}
.modal-in select {
    width: 68%;
    float: right;
    height:52px;
    margin-right:0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../resources/admin/icon/droparrow.svg") no-repeat 95% 50%;
}
.pay-list li {
    padding:15px 0;
    margin-bottom:0;
    border-bottom:0.5px solid #DFDFDF;
    font-size:18px;
}
.pay-list li strong {
    font-weight:500;
    color:#000000;
    text-align:left;
}
.pay-list li span {
    float:right;
    color:#727272;
}
.modal-in li:after, .pay-list li:after {
    content:"";
    display:block;
    clear:both;
}
