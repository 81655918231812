/*sub 공통*/
#wrap {
    width: 100%;
    height: auto;
    overflow: hidden;
}
#sub-container {
    position:relative;
    background:#fff;
    margin-top:208px;
    z-index: 6;
}
#top-menu {
    width: 100%;
    position: fixed;
    top: 100px;
    left: 0;
}
#top-menu .section-in {
    position:relative;
}
.top-title {
    padding:78px 0;
    font-size:40px;
    font-weight:300;
}
.top-title strong {
    display: block;
    width:52%;
    font-weight:700;
}
.location {
    position:absolute;
    top:156px;
    right:0;
}
.sv-location {
    top:104px;
}
.location a, .location strong {
    display:inline-block;
    font-size:14px;
    font-weight:400;
    line-height: 1.3;
    vertical-align: middle;
    color:#595959;
}
.location a img {
    width: 18px;
    opacity: 0.65;
}
.location strong::before {
    content:"";
    display:inline-block;
    width:8px;
    height:18px;
    background:url("../resources/homepage/location_line.png") no-repeat center;
    margin: 0 12px;
    vertical-align: bottom;
}
#sub-container .section-in {
    padding:80px 0 84px;
}
#sub-container .section-in.sv-in {
    padding-top:50px;
}
.sub-title {
    font-size:24px;
    line-height:1.3;
    color:#000;
}
.pc-long .top-title {
    padding:78px 0 30px;
}
#sub-container.other {
    margin-top: 300px;
}
.red {color:#DA2128;}
.green {color:#00934B;}
.respon-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    animation-fill-mode: backwards !important;
}
.basic-table.sub-table {
    width:100%;
    display: inline-table;
    margin-top: 0;
    white-space: inherit;
}
.basic-table.sub-table th {
    padding: 20px 24px;
    font-weight: bold;
    background: #f9f9f9;
    vertical-align: middle;
}
.basic-table.sub-table td {
    padding: 18px 24px;
    vertical-align: middle;
}
.sub-table .no-contents td {
    padding: 200px 0;
}
.basic-table.sub-table tbody tr:last-child {
    border-bottom:0;
}
.basic-table.sub-table.full-table{
    min-height: auto;
}
.full-table thead, .full-table tbody, .full-table tr {
    width:100%;
}
.full-table th, .full-table td {
    width:50%;
    text-align: center;
}
.middle, .basic-table th.middle, .basic-table.sub-table td.middle {
    text-align: center;
}
/*sub 공통*/

/*modal*/
#wrap #basic-modal {
    z-index: 9999;
}
#wrap #basic-modal > div {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.pc-modal {
    width:720px;
    padding:80px 50px;
    border:2px solid #00934B;
    box-sizing:border-box;
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
}
.pc-modal #modal-close {
    display: none;
}
.pc-modal-in {
    width: 100%;
    height: 50vh;
    margin: 20px auto 60px;
    padding:15px 15px 15px 0;
    overflow-y: scroll;
}
.pc-modal-in p {
    font-size:18px;
    line-height:1.4;
    color:#282828;
}
.pc-modal-in p + p {
    margin-top: 30px;
}
.pc-modal-in::-webkit-scrollbar { width: 3px; }
.pc-modal-in::-webkit-scrollbar-track { background-color:#ccc; }
.pc-modal-in::-webkit-scrollbar-thumb { background: #00934B; }
.pc-modal-in::-webkit-scrollbar-thumb:hover { background: #00934B; }
.pc-modal-in::-webkit-scrollbar-thumb:active { background: #00934B; }
.pc-modal-in::-webkit-scrollbar-button { display: none; }
.pc-modal h1 {
    font-size:24px;
    padding-bottom: 10px;
}
.pc-m-title {
    width:100%;
    font-size:0;
}
.pc-m-title > * {
    display: inline-block;
}
.pc-m-title > img {
    width:80px;
    margin-right:20px;
    vertical-align: bottom;
}
/*modal*/

/*responsive*/
@media all and (max-width:1280px) {
    #sub-container .section-in {
        padding: 10px 0 84px;
    }
    .location {
        top: 130px;
    }
    .sv-location {
        top: 92px;
    }
    .sub-title {
        font-size:24px;
    }
    .pc-modal-in p {
        font-size:16px;
        line-height:1.8;
    }
    .modal-btn {
        width: 120px;
        height: 40px;
        font-size: 16px;
    }
}

@media all and (max-width:1024px) {
    .top-title {
        padding: 48px 0 0px;
    }
    .top-title strong {
        width: 100%;
        float: none;
    }
    .location {
        position: initial;
        padding:10px 0 30px;
    }
    .sv-long-in .top-title {
        width:38%;
    }
    .location {
        top: 102px;
    }
    .sv-location {
        top:60px;
    }
    #sub-container.other {
        margin-top:350px;
    }
    .sv-long-in #sub-container {
        margin-top: 190px;
    }
    .basic-table.sub-table th, .basic-table.sub-table td, .pc-modal-in p {
        font-size:15px;
    }
}

@media all and (max-width:978px) {
    #wrap #basic-modal > div {
        width: 90%;
    }
    .pc-modal {
        width:100%;
        padding: 60px 30px;
        animation-fill-mode:backwards !important;
    }
    .sv-long-in .top-title {
        width: 100%;
    }
}
@media all and (max-width:767px) {
    #sub-container {
        margin-top: 32vh;
    }
    #sub-container.notice-con {
        margin-top: 25vh;
    }
    #sub-container.other {
        margin-top: 45vh;
    }
    .sub-title, .pc-modal h1 {
        font-size: 20px;
    }
    .pc-long .top-title {
        padding: 78px 0 12px;
    }
}

@media all and (max-width:640px) {
    #top-menu {
        top:80px;
    }
    .pc-m-title > img {
        width:60px;
    }
    #wrap #basic-modal .modal .btn-wrap {
        margin-top: 10px;
    }
    .pc-modal-in {
        margin:10px auto 30px;
    }
    .sv-long-in #sub-container {
        margin-top: 250px;
    }
    .respon-table .sv-table.w-long {
        width: 150%;
    }
}

@media all and (max-width:480px) {
    #sub-container {
        margin-top: 250px;
    }
    #sub-container.notice-con {
        margin-top: 24vh;
    }
    #sub-container.other {
        margin-top: 400px;
    }
    .sv-long-in #sub-container {
        margin-top: 220px;
    }
    .location img {
        width: 16px;
    }
    .location strong {
        font-size: 14px;
    }
    .location strong::before {
        width:5px;
    }
}

@media all and (max-width:375px) {
    #sub-container {
        margin-top: 220px;
    }
    #sub-container.notice-con {
        margin-top: 200px;
    }
    #sub-container.other {
        margin-top: 380px;
    }
    .sv-long-in #sub-container {
        margin-top: 220px;
    }
    .sv-nav li {
        font-size: 12px;
    }
}

@media all and (max-width:340px) {
    #sub-container, #sub-container.notice-con {
        margin-top: 200px;
    }
    #sub-container.other {
        margin-top: 380px;
    }
    .sv-long-in #sub-container {
        margin-top: 220px;
    }
    .pc-long .top-title {
        padding: 66px 0 12px;
    }
}
