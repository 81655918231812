/*header*/
#header {
    width:100%;
    height:94px;
    line-height:94px;
    background: #fff;
    border-bottom:1px solid #dfdfdf;
    transition: all .2s linear;
}
.menu-icon {
    position:absolute;
    top:50%;
    right:0;
    transform:translateY(-50%);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    height: 32px;
    width: 32px;
    padding:4px;
    cursor:pointer;
    z-index: 9999;
}
.sticky-wrap > div {
    position: relative;
    z-index: 999;
}
.header-in, .section-in, .all-navi-inner {
    position: relative;
    width:100%;
    max-width:1280px;
    margin:0 auto;
}
.all-navi-inner {
    padding-top: 15vh;
}
.logo {
    float:left;
}
.gnb {
    float:right;
    font-size: 0;
    padding-right: 24px;
}
.gnb > li {
    position: relative;
    display:inline-block;
    padding:0 24px;
    font-size:18px;
    color:#000;
    cursor: pointer;
    vertical-align: middle;
}
.gnb > li:last-child {
    padding-right:0;
}
.sticky #header, .sticky .menu-wrap {
    height:74px;
    line-height:74px;
}
.sticky #header .logo img {
    width:160px;
}
.sticky #header .gnb > li {
    height:74px;
    line-height: 74px;
}
.header-in:after {
    content:"";
    display:block;
    clear:both;
}
.depth {
    opacity: 0;
    width: 210px;
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 18px;
    color: #fff;
    line-height: 2;
    border-radius: 8px;
    -webkit-box-shadow: 7px 7px 30px rgba(0, 0, 0, 0.12);
    box-shadow: 7px 7px 30px rgba(0, 0, 0, 0.12);
    transition: all .5s ease-in-out;
    z-index: -2;
}
.depth li a {
    display: none;
    font-size: 16px;
    line-height: 2.4;
    color: #fff;
    text-align: left;
}
.gnb > li:hover .depth {
    padding: 20px 25px;
    background: #00934B;
    opacity: 1;
    z-index: 8;
}
.gnb > li:hover .depth li a {
    display: block;
}
.all-navi-inner #navi-close {
    float:right;
}
.all-gnb {
    /*animation:slideDown .5s linear;*/
    width:100%;
    height:100vh;
    position: absolute;
    top:0;
    right:0;
    background:#eff9f3;
    z-index:999;
}
.all-gnb {
    animation:         menu-bg .5s ease-in-out 1; /* IE 10+, Fx 29+ */
    -webkit-animation: menu-bg .5s ease-in-out 1; /* Safari 4+ */
    -moz-animation:    menu-bg .5s ease-in-out 1; /* Fx 5+ */
    -o-animation:      menu-bg .5s ease-in-out 1; /* Opera 12+ */
}
@keyframes menu-bg {
    0% {
        transform:translateY(-100%);
        border-radius: 0 0 0 0;
    }
    100% {
        transform:translateY(0);
        border-radius: 0 0 100% 100%;
    }
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-2%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes shrink {
    0% {
        width: 95%;
    }
    100% {
        width: 90%;
    }
}
.all-in > ul > li {
    float: left;
    width: 25%;
    padding-top: 30px;
}
.all-in ul:after {
    content:"";
    display:block;
    clear:both;
}
.all-in .dep1 {
    font-size:28px;
    font-weight:600;
    color:#000;
    cursor: default;
}
.all-in .gnb-inner {
    margin-top:40px;
}
.all-in .gnb-inner li {
    display: block;
    margin-bottom:30px;
    font-size:20px;
    font-weight: 600;
    line-height:1.2;
}
.all-in .gnb-inner > li a {
    font-size:18px;
    color:#505050;
}
.sub-gnb {
    margin-bottom:50px;
}
.all-in .long-gnb .gnb-inner .sub-gnb li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 14px;
    font-size:16px;
    font-weight: normal;
}
.all-in .long-gnb .sub-gnb li a {
    color:#5a5a5a;
}
.all-in .sub-gnb li::before {
    content: '';
    position: absolute;
    top: 8.5px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: #00934B;
    border-radius: 50%;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 100; /* this is optional and should be different for every project */
}
/*header*/


/*responsive*/
@media all and (max-width:1280px) {
    .header-in, .section-in, .all-navi-inner, .footer-in, .menu-wrap {
        max-width:90%;
    }
    .top-title {
        font-size: 30px;
    }
    .all-in {
        width:100%;
        margin:0 auto;
    }
    .all-in > ul > li {
        width:23%;
    }
    .all-in > ul > li.long-gnb {
        width:31%;
    }
    .all-in .dep1 {
        font-size: 22px;
    }
}

@media all and (max-width:1024px) {
    #header {
        height:84px;
        line-height: 84px;
    }
    .all-gnb {
        animation:         menu-bg .5s ease-in-out 1 reverse; /* IE 10+, Fx 29+ */
        -webkit-animation: menu-bg .5s ease-in-out 1 reverse; /* Safari 4+ */
        -moz-animation:    menu-bg .5s ease-in-out 1 reverse; /* Fx 5+ */
        -o-animation:      menu-bg .5s ease-in-out 1 reverse; /* Opera 12+ */
    }
    @keyframes menu-bg {
        0% {
            transform:translateX(0);
            border-radius: 0 0 0 0;
        }
        100% {
            transform:translateX(100%);
            border-radius: 100% 0 0 100%;
        }
    }
    .all-gnb::-webkit-scrollbar {
         display: none;
     }
    .all-navi-inner {
        padding-top:6vh;
    }
    .logo > a > img {
        width:166px;
    }
    .sticky #header {
        height:68px;
        line-height:68px;
    }
    .sticky #header .logo img {
        width:156px;
    }
    .gnb > li {
        display: none;
    }
    .gnb > li:last-child {
        display: block;
    }
    .all-in .dep1 {
        font-size: 24px;
    }
    .all-in > ul > li {
        padding-top:40px;
    }
    .all-in ul > li a {
        font-size: 16px;
    }
    .all-gnb {
        width:100vw;
        overflow-y: scroll;
    }
    .all-in > ul > li, .all-in > ul > li.long-gnb {
        width:90%;
        margin: auto;
        float:none;
    }
    .all-in .gnb-inner {
        margin-top: 0px;
    }
    .all-in .gnb-inner li {
        margin-bottom: 20px;
    }
    .all-in > ul > li:nth-child(2) > ul {
        display: none;
    }
    .m-overlay {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background:rgba(0, 0, 0, 0.3);
    }
    .dep1, .gnb-inner {
        width:50%;
        float: left;
    }
    .all-in > ul > li:after {
        content:"";
        display: block;
        clear: both;
    }
}
@media all and (max-width:640px) {
    #header {
        height: 74px;
        line-height: 72px;
    }
    .sticky #header, .sticky #header .gnb > li, .sticky .menu-wrap {
        height:58px;
        line-height: 56px;
    }
    .sticky #header .logo img {
        width: 136px;
    }
    .all-gnb {
        width: 100vw;
    }
    .dep1 {
        width:40%;
    }
    .gnb-inner {
        width:calc(60% - 20px);
        float: right;
    }
    .all-in > ul > li {
        padding-top: 3vh;
    }
    .all-in > ul > li, .all-in > ul > li.long-gnb {
        width: 94%;
    }
    .all-in .gnb-inner li {
        margin-bottom: 20px;
    }
    .all-in .dep1 {
        font-size: 20px;
    }
    .all-in .gnb-inner > li a {
        font-size:16px;
    }
    .all-in .long-gnb .gnb-inner .sub-gnb li a {
        font-size: 14px;
    }

}

@media all and (max-width:480px) {
    .top-title {
        font-size: 28px;
    }
    .all-in {
        padding-bottom: 80px;
    }
    .all-in > ul > li, .all-in > ul > li.long-gnb {
        width: 100%;
    }
    .tabs {
        margin: 0 0 20px;
    }
}

@media all and (max-width:375px) {
    .top-title {
        font-size: 25px;
    }
    .dep1, .gnb-inner {
        width:100%;
        float: none;
    }
    .all-in .gnb-inner li {
        margin-top: 20px;
    }
    .sub-gnb {
        margin-bottom: 25px;
    }
    .all-in .gnb-inner {
        padding-left: 22px;
    }
    .all-in > ul > li {
        padding-top: 4vh;
    }
}