#section-1 {
    position: relative;
    padding-top:100px;
}
.main-bg {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:93vh;
    background:url("../resources/homepage/main-bg.png") no-repeat center / cover;
    background-attachment: fixed;
    animation:         zoom-bg 6s linear 1 reverse; /* IE 10+, Fx 29+ */
    -webkit-animation: zoom-bg 6s linear 1 reverse; /* Safari 4+ */
    -moz-animation:    zoom-bg 6s linear 1 reverse; /* Fx 5+ */
    -o-animation:      zoom-bg 6s linear 1 reverse; /* Opera 12+ */
}
@keyframes zoom-bg {
    0% {
        -ms-transform: scale(1, 1);
        transform: scale(1, 1) translateZ(1px);
    }
    100% {
        -ms-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2) translateZ(1px);
    }
}
.big-circle {
    z-index: -1;
    position: absolute;
    width: 690px;
    height: 690px;
    background-image: linear-gradient(45deg, #4ece39, #00a856);
    border-radius: 100%;
    bottom: -330px;
    left: 50%;
    margin-left: -345px;
}
.shadow, .ph-back {
    position: absolute;
    left: 48%;
    transform: translateX(-50%);
    z-index: -1;
    width: 115%;
    opacity: 0.75;
}
.ph-back {
    width: 100%;
    left: 50%;
    opacity: 1;
}
.m-title {
    font-size:44px;
    line-height:1.3;
    font-weight:400;
    color:#000;
    text-align: center;
}
.m-title strong {
    font-weight:600;
}
.s-title {
    padding-top:16px;
    font-size:18px;
    line-height: 1.6;
    font-weight:normal;
    color:#444444;
    text-align: center;
}
#section-1 .download {
    margin: 40px auto;
    text-align: center;
}
.m-phone {
    position: relative;
    width: 400px;
    margin: auto;
}
.slide-inner {
    width: 85%;
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    background: #f9f9f9;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
    z-index: 1;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before, .slick-prev:before, .slick-next:before {
    opacity: 0;
}
#section-2 {
    position: relative;
    padding:100px 0;
    background:#f9f9f9;
}
#section-2 .s-title {
    padding:30px 0 28px;
}
#section-2 .s-title.mobile {
    display: none;
}
.icon-list {
    font-size:0;
    text-align: center;
}
.icon-list li {
    display:inline-block;
    padding-top: 32px;
    width:152px;
    height:152px;
    border-radius:50%;
    background:#fff;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.icon-list li img {
    display:block;
    margin: 0 auto 12px;
}
.icon-list li span {
    font-size:18px;
    text-align:center;
}
.icon-list li:nth-child(2) {
    margin:0 50px;
}
#section-3 .left {
    width:48%;
    float:left;
    padding-top: 10px;
    transform: translateY(25%);
}
#section-3 .s-title.mobile {
    display: none;
}
#section-3 .right {
    width: 52%;
    float:right;
}
#section-3 .section-in:after {
    content:"";
    display:block;
    clear:both;
}
#section-3 .m-title, #section-3 .s-title {
    text-align: left;
}
#section-3 .s-title {
    padding:36px 0 28px;
}
#section-3 a {
    font-size:18px;
    color:#00934B;
}
#section-3 a:hover {
    font-weight:bold;
}
#section-3 a img {
    transform: translateX(10px);
    transition: all .3s ease-in;
    vertical-align: inherit;
}
#section-3 a:hover > img {
    transform: translateX(20px);
}
#section-3 .right li {
    position: relative;
    text-align:right;
}
#section-3 .right li:nth-child(2) {
    text-align: left;
    margin-top: -80px;
}
#section-3 .right li:nth-child(3) {
    margin-top: -80px;
}
#section-3 .right li span {
    position: absolute;
    top:51%;
    transform:translateY(-50%);
    right: 118px;
    font-size:17px;
    color:#fff;
    text-align:left;
}
#section-3 .right li span.mobile {
    display: none;
}
#section-3 .right li:nth-child(2) span {
    color:#000;
    left:126px;
}
#section-3 .right li:nth-child(3) span {
    right:124px;
}
#section-4 {
    background:#f6fbf8;
    padding:100px 0;
}
#section-4 .pro-wrap {
    position: relative;
    width:52%;
    margin:0 auto;
}
#section-4 .pro-wrap > div:nth-child(1) {
    margin: 90px 0 20px;
}
#section-4 .pro-wrap > div:nth-child(2) {
    position: absolute;
    width: 90%;
    top: -42px;
    right: -15%;
}
#section-4 .m-btn {
    text-align: center;
}
#section-4 .m-btn a {
    display: inline-block;
    width:302px;
    height:70px;
    margin-right:30px;
    line-height:68px;
    border-radius: 50px;
    background:#fff;
    border:1px solid #00934B;
    font-size:22px;
    color:#00934B;
    text-align: center;
    vertical-align: initial;
    transition: all .3s ease-in;
    box-sizing: border-box;
}
#section-4 .m-btn a:hover {
    box-shadow: 0 9px 25px 0 rgba(0, 147, 75, 0.1);
}
#section-4 a img {
    transform: translateX(10px);
    transition: all .3s ease-in;
    vertical-align: inherit;
}
#section-4 a:hover > img {
    transform: translateX(20px);
}
#section-5 {
    padding:100px 0;
    background:#0e110e;
}
#section-5 .left {
    width:48%;
    float:left;
    padding-top: 10px;
}
#section-5 .s-title.mobile {
    display: none;
}
#section-5 .right {
    width: 52%;
    float:right;
}
#section-5 .right.mobile {
    display: none;
}
#section-5 .section-in:after {
    content:"";
    display:block;
    clear:both;
}
#section-5 .m-title, #section-5 .s-title {
    text-align: left;
    color:#fff;
}
#section-5 .s-title {
    padding:36px 0 28px;
    text-align: left;
    color:#fff;
}
#section-5 .right li {
    display: inline-block;
    width:33%;
    margin-bottom: 56px;
    text-align: center;
}
#section-5 .right li:nth-child(7), #section-5 .right li:nth-child(8), #section-5 .right li:nth-child(9) {
    margin-bottom: 0;
}
#section-5 .right li .cs-imgon {
    display: none;
}
#section-5 .right li:hover .cs-img {
    display: none;
}
#section-5 .right li:hover .cs-imgon {
    display:inline-block
}
#section-5 .hp-list {
    margin-top:30px;
}
#section-5 .hp-list li {
    font-size:18px;
    line-height:1.4;
    color:#fff;
    padding:15px 0;
    border-bottom:1px solid #676767;
}
#section-5 .hp-list li span {
    width:30px;
    float:left;
}
#section-5 .hp-list li div {
    width: calc(100% - 30px);
    float:right;
}
#section-5 .hp-list li span {
    display:block;
    width:20px;
    height:20px;
    margin-top: 3px;
    margin-right:10px;
    line-height: 20px;
    border-radius: 50%;
    background:#fff;
    font-size:14px;
    font-weight:bold;
    color:#000;
    text-align: center;
}
#section-5 .hp-list li:after, #section-5:after {
    content:"";
    display:block;
    clear:both;
}
#section-5 .hp-left {
    width:calc(49% - 50px);
    float:left;
}
#section-5 .hp-right {
    width:calc(49% - 50px);
    float: right;
}

@media all and (max-width:1200px) {
    .m-title {
        font-size:30px;
    }
    .s-title {
        font-size:16px;
    }
    #section-1 .download {
        margin:30px auto;
    }
    #section-1 .down-btn {
        width: 80px;
        height: 80px;
    }
    #section-1 .download a {
        width: 180px;
        height: 56px;
        line-height: 54px;
    }
    #section-1 .download a img {
        width:114px;
    }
    .icon-list li {
        width:130px;
        height:130px;
        padding-top: 24px;
    }
    .icon-list li img {
        margin: 0 auto 12px;
        height: 46px;
    }
    .icon-list li span, #section-3 a, #section-3 .right li span {
        font-size:16px;
    }
    #section-3 .left {
        padding-top: 1vh;
    }
    #section-3 .s-title {
        display: none;
    }
    #section-3 .s-title.mobile {
        display: block;
    }
    #section-3 .left {
        padding-top: 5vh;
    }
    #section-3 .right li:nth-child(1) img{
        width:450px;
    }
    #section-3 .right li:nth-child(2) img{
        width:473px;
    }
    #section-3 .right li:nth-child(3) img{
        width:386px;
    }
    #section-3 .right li span.mobile {
        font-size:14px;
        right:94px;
        width: 62%;
    }
    #section-3 .right li:nth-child(2) span {
        left: 113px;
        width: 70%;
    }
    #section-3 .right li:nth-child(2), #section-3 .right li:nth-child(3) {
        margin-top: -50px;
    }
    #section-4 .pro-wrap {
        width:56%;
    }
    #section-4 .m-btn a {
        font-size:18px;
        width: 240px;
        height: 60px;
        line-height: 60px;
        margin-right: 15px;
    }
    #section-4 .m-btn a:last-child {
        margin-right: 0;
    }
    #section-5 .left {
        width:42%;
        padding-top:3vh;
    }
    #section-5 .right {
        width: 58%;
    }
}


@media all and (max-width:978px) {
    .m-phone {
        width:300px;
    }
    .big-circle {
        width: 600px;
        height: 600px;
        margin-left: -300px;
    }
    #section-2 .s-title {
        display: none;
    }
    #section-2 .s-title.mobile {
        display: block;
    }
    #section-3 .left {
        padding-top: 1vh;
    }
    #section-3 .s-title {
        display: none;
    }
    #section-3 .s-title.mobile {
        display: block;
    }
    #section-3 .right li:nth-child(1) {
        width:474px;
        margin-left: auto;
    }
    #section-3 .right li:nth-child(2) {
        width:493px;
    }
    #section-3 .right li:nth-child(3) {
        width:406px;
        margin-left: auto;
    }
    #section-3 .right li:after {
        content:"";
        display:block;
        clear:both;
    }
    #section-3 .right li:nth-child(1) span.mobile {
        width: 58%;
        left: 10vw;
    }
    #section-3 .right li:nth-child(2) span.mobile {
        width: 60%;
        left: 16vw;
    }
    #section-3 .right li:nth-child(3) span {
        width: 55%;
        left: 9vw;
    }
    #section-4 .m-btn a {
        font-size: 16px;
        width: 222px;
    }
    #section-5 .left {
        padding-top:2vh;
    }
    #section-5 .right {
        display: none;
    }
    #section-5 .right.mobile {
        display: block;
    }
    #section-5 .hp-list li {
        font-size:16px;
    }
}

@media all and (max-width:876px) {
    #section-1 {
        padding-top: 10vh;
    }
    #section-2, #section-4, #section-5 {
        padding:10vh 0;
    }
    .icon-list li:nth-child(2) {
        margin: 0 4%;
    }
    #section-3 .left {
        padding-top: 0;
    }
    #section-3 .left, #section-3 .right, #section-5 .left, #section-5 .right {
        width:100%;
        float:none;
    }
    #section-3 .right, #section-5 .right.mobile {
        margin-top:5vh;
    }
    #section-4 .pro-wrap {
        width: 65%;
    }
    #section-5 .s-title {
        display: none;
    }
    #section-5 .s-title.mobile {
        display: block;
    }
    #section-5 .hp-left, #section-5 .hp-right {
        width:100%;
        float:none;
    }
    #section-5 .hp-right {
        margin-top:80px;
    }
}

@media all and (max-width:640px) {
    .main-bg {
        background:url("../resources/homepage/main-bg-m.png") no-repeat center / cover;
    }
    .big-circle {
        width: 430px;
        height: 430px;
        margin-left: -215px;
        bottom: -30vh;
    }
    #section-3 .right li:nth-child(1) span.mobile {
        left: 14vw;
    }
    #section-3 .right li:nth-child(2) span.mobile {
        left: 23vw;
    }
    #section-3 .right li:nth-child(3) span {
        left:13vw;
    }
    #section-4 .pro-wrap {
        width:70%;
    }
    #section-4 .m-btn a {
        font-size: 16px;
        width: 198px;
        height: 48px;
        line-height: 46px;
    }
    #section-4 a img {
        width:7px;
        transform: translateX(7px);
    }
    #section-4 a:hover > img {
        transform: translateX(5px);
    }
}

@media all and (max-width:480px) {
    #section-1 {
        padding-top: 7vh;
    }
    .main-bg {
        background-position: right;
    }
    .big-circle {
        width: 380px;
        height: 380px;
        margin-left: -190px;
        bottom: -30vh;
    }
    .m-title {
        font-size: 28px;
    }
    .s-title, #section-3 a {
        font-size: 15px;
    }
    .m-phone {
        width: 64%;
    }
    #section-1 .s-title {
        width:80%;
        margin: auto;
    }
    #section-1 .download {
        margin: 20px auto;
    }
    #section-1 .download a {
        width: 134px;
        height: 43px;
        line-height: 44px;
        margin-right: 8px;
    }
    #section-1 .download a img {
        width: 90px;
    }
    .icon-list li {
        width: 90px;
        height: 90px;
        padding-top: 16px;
    }
    .icon-list li span {
        font-size:13px;
    }
    .icon-list li img {
        margin: 0 auto 7px;
        height: 32px;
    }
    #section-3 {
        padding:0 0 5vh;
    }
    #section-3 .s-title.mobile {
        width:80%;
    }
    #section-3 .right {
        margin-top: 10vh;
    }
    #section-3 .right li span {
          display: none;
    }
    #section-3 .right li span.mobile {
        display: block;
        font-size: 13px;
        left: 40px;
        right: initial;
    }
    #section-3 .right li:nth-child(1), #section-3 .right li:nth-child(2), #section-3 .right li:nth-child(3), #section-3 .right li:nth-child(1) img, #section-3 .right li:nth-child(2) img {
        width:100%;
    }
    #section-3 .right li:nth-child(1) span.mobile {
        left: 11vw;
        width: 60%;
    }
    #section-3 .right li:nth-child(2) span.mobile {
        left:98px;
    }
    #section-3 .right li:nth-child(2), #section-3 .right li:nth-child(3) {
        margin-top: -4vh;
    }
    #section-3 .right li:nth-child(3) span.mobile {
        width:50%;
        left:23vw;
        word-break: keep-all;
    }
    #section-3 .right li:nth-child(3) img {
        width:86%;
    }
    #section-4 .m-btn {
        margin-top: 30px;
    }
    #section-4 .pro-wrap {
        width: 86%;
    }
    #section-4 .pro-wrap > div:nth-child(1) {
        margin: 60px 0 0;
    }
    #section-4 .pro-wrap > div:nth-child(2) {
        top:-26px;
        right:-10%;
    }
    #section-4 .m-btn a, #section-4 .m-btn a:last-child {
        display: block;
        margin:10px auto 0;
    }
    #section-5 .right.mobile {
        margin-top: 3vh;
    }
    #section-5 .right li img {
        width:80%;
    }
    #section-5 .right li:nth-child(1) img, #section-5 .right li:nth-child(3) img, #section-5 .right li:nth-child(6) img, #section-5 .right li:nth-child(9) img {
        width:46%;
    }
}

@media all and (max-width:375px) {
    .m-title {
        font-size: 26px;
    }
    #section-1 .down-btn {
        width: 72px;
        height: 72px;
        padding-top: 16px;
        right:3%;
        font-size:14px;
    }
    #section-3 .right {
        margin-top: 15vh;
    }
    #section-3 .right li span.mobile {
        left:36px;
    }
    #section-3 .right li:nth-child(1) span.mobile {
        width: 66%;
        left: 9vw;
    }
    #section-3 .right li:nth-child(2) span.mobile {
        width: 66%;
        left: 82px;
    }
    #section-3 .s-title, #section-5 .s-title {
        padding: 26px 0 18px;
    }
    .slide-inner {
        border-radius:40px 40px 0 0;
    }
}

@media all and (max-width:330px) {
    #section-3 .right li span.mobile {
        font-size: 11px;
        left: 30px;
    }
    #section-3 .right li:nth-child(1) span.mobile {
        left: 9vw;
        width: 65%;
    }
    #section-3 .right li:nth-child(2) span.mobile {
        width: 65%;
        left:70px;
    }
    .slide-inner {
        border-radius:30px 30px 0 0;
    }
}