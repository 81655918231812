/*top*/
#ad-top {
    align-self: stretch;
    top:0;
    left: 0;
    height:70px;
    background:#00934B;
    padding:0 42px;
}
.top-left {
    float:left;
    padding-top: 20px;
}
.top-right {
    float:right;
    padding-top: 24px;
}
.top-right span {
    font-size:14px;
    color:#fff;
    float:left;
    cursor: pointer;
}
.top-right span a {
    color:#fff;
    cursor: pointer;
}
.top-right span:first-child {
    padding-right:14px;
    margin-right:14px;
    border-right:1px solid rgba(255, 255, 255, 0.5);
}
#ad-top:after {
    content:"";
    display:block;
    clear: both;
}
.clear { clear: both }
/*top*/

/*menu*/
#ad-menu {
    align-self: stretch;
    width:250px;
    padding:54px 38px;
    background:#F9F9F9;
    float:left;
}
#ad-menu li {
    margin-bottom:24px;
    display: block;
    font-size:18px;
    line-height: 1.6;
    color:#282828;
    cursor: pointer;
}
#ad-menu li:last-child a {
    display: block;
    padding-bottom: 2px;
    width: 200px;
}

#ad-menu li span {
    display: block;
    font-size: 14px;
    color: #767676;
    margin-bottom: 4px;
}
/*
#ad-menu li a:hover {
    color:#00934B;
    font-weight:bold;
}
*/
/*menu*/

/*main-contents 공통*/
#ad-contents {
    padding:72px 82px;
    background-color: #fff;
}
.contents-in {
    position:relative;
}
.contents-title {
    font-size:30px;
    font-weight:500;
    color:#000;
    padding-bottom:20px;
}
/*main-contents 공통*/

/*main-contents 표 공통*/
.schfrm input[type="text"], .schfrm input[type="password"] {
    height:40px;
}
.schfrm input::placeholder {
    font-size: 16px;
    color: #8d8d8d;
    opacity: 1;
}

.schfrm .left {
    float:left;
    padding-top: 10px;
    font-size:16px;
    font-weight:400;
    color:#000;
}
.schfrm .left strong {
    font-size:20px;
    font-weight:bold;
    color:#da2128;
}
.schfrm .right {
    float:right;
}
.schfrm .right > * {
    float:left;
}
.schfrm select {
    width:134px;
    height:40px;
    line-height: 1;
    border-radius:4px;
    margin-right:10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../resources/admin/icon/droparrow.svg") no-repeat 90% 50%;
}
.schfrm .search-box {
    position:relative;
    width:306px;
    height:40px;
    margin-right:8px;
}
.schfrm .search-box .search {
    position:absolute;
    top:0;
    left:0;
    width:304px;
}
.schfrm .search-btn {
    width: 40px;
    height: 100%;
    position: absolute;
    right: 10px;
    background: url("../../resources/admin/icon/search.svg") no-repeat center;
    cursor: pointer;
}
.schfrm .enroll {
    width:92px;
    height:40px;
    background:#00934b;
    border-radius: 4px;
    font-size:16px;
    color:#fff;
}
input[type="date"]:first-child, .pay-check .radio  {
    margin-left:20px;
}
.date-pick span {
    display:inline-block;
    margin:0 5px;
}
.d-search {
    width: 80px;
    height: 34px;
    margin-left:10px;
    background:#606060;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    color: #fff;
}
.pay-check {
    margin:16px 0 20px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #dfdfdf;
}
.schfrm:after, .schfrm .right:after {
    content:"";
    display:block;
    clear:both;
}

.basic-table {
    position: relative;
    width:100%;
    /*min-height: 500px;
    display: block;*/
    margin:20px auto;
    border-top:2px solid #000;
    /*border-bottom:0.5px solid #DFDFDF;*/
    white-space: nowrap;
}
.basic-table thead tr {
    border-bottom:0.5px solid #DFDFDF;
}
.basic-table tbody tr {
    border-bottom:0.5px solid #DFDFDF;
}
.basic-table th {
    padding:20px 16px;
    font-size:18px;
    font-weight:normal;
    color:#000;
    word-break: break-all;
    text-align:left;
}
.basic-table td {
    padding:16px;
    font-size:18px;
    font-weight:normal;
    color:#282828;
    text-align:left;
}
.basic-table td .payment {
    color:#00934B;
    border-bottom:0.5px solid #00934B;
    cursor:pointer;
}
.basic-table td .setting {
    padding-left:8px;
    cursor:pointer;
}
.no-contents td {
    padding:200px 0;
    text-align:center;
    color:#8D8D8D;
    border-bottom:0;
}
#ad-contents .check {
    margin-right:0;
}
.check input[type="checkbox"] {
    display: none;
}
.check input[type="checkbox"] + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #ACACAC;
    border-radius: 3px;
    cursor: pointer;
}
.check:after {
    display: block;
    clear: both;
    content: "";
}
.check input[type="checkbox"]:checked + label {
    background: url("../../resources/admin/icon/check.svg") #5A5A5A no-repeat center/10px 10px;
    border-color: #5A5A5A;
}
.check {
    position: relative;
}
.check input[type="checkbox"] + label span {
    position: absolute;
    top: -6px;
    left: 30px;
    display: block;
    font-size: 16px;
    color: #ACACAC;
}
.ad-pager {
    display: inline-block;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
}
.ad-pager > li {
    float: left;
    font-size: 16px;
    color: #8D8D8D;
    padding: 0 14px;
    cursor: pointer;
}
.ad-pager > li.on {
    color: #00934B;
    font-weight:bold;
}
.ad-pager:after {
    content: "";
    display: block;
    clear: both;
}
.ad-delete {
    width:72px;
    height:32px;
    line-height:32px;
    background:#5a5a5a;
    border-radius: 4px;
    font-size:14px;
    text-align: center;
    color:#fff;
}
.basic-table th.editTit, .basic-table td.edit {
    text-align:center;
}
.edit button, .editBusiness button {
    width:80px;
    height:34px;
    border: 1px solid #838383;
    background:#fff;
    border-radius: 4px;
    font-size:16px;
    text-align: center;
    color:#838383;
}
.edit.write button {
    border-color:#838383;
    color:#838383;
}
/*main-contents 표 공통*/

/*main-contents*/
.dash-left {
    width:50%;
    float: left;
}
.dl-top li {
    width:32.6%;
    padding:50px 0;
    border-radius: 4px;
    border:1px solid #DFDFDF;
    background:#f9f9f9;
    text-align:center;
    float:left;
}
.dl-top li:nth-child(2) {
    margin:0 1%;
}
.dl-top li h3 {
    font-size:20px;
    font-weight:500;
    color:#000;
}
.dl-top li h2 {
    font-size:40px;
    font-weight:700;
    color:#00934b;
}
.dl-top li h2 small {
    font-size:18px;
}

.dl-bottom {margin-top:50px;}
.da-subTit {margin-bottom:15px;}
.da-subTit h5 {
    display:block;
    width:50%;
    font-size:20px;
    font-weight:500;
    color:#000;
    float:left;
}
.da-subTit a {
    display:block;
    width:50%;
    padding-top:5px;
    font-size:14px;
    text-align:right;
    color:#000;
    float:right;
    cursor: pointer;
}
.dl-bottom li {
    width:100%;
    padding:18px;
    margin-bottom:6px;
    border:1px solid #8D8D8D;
    border-radius:4px;
    cursor: pointer;
}
.dl-bottom li > * {
    float:left;
}
.dl-bottom li a {
    margin:0 20px;
    padding-top:12px;
}
.dl-bottom li span {
    padding-top:12px;
    float:right;
    color:#818181;
}
.dl-bottom li .text {
    width:65%;
    padding-top:12px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.dash-right {
    width:45%;
    float: right;
}
.dr-top table {
    width:100%;
    margin:15px auto 60px;
}
.dr-top table th {
    width:33.33%;
    padding:12px 14px;
    border-top:2px solid #000;
    border-bottom:0.5px solid #DFDFDF;
    font-size:16px;
    font-weight:normal;
    color:#7A7A7A;
    text-align: left;
}
.dr-top table td {
    width:33.33%;
    padding:18px 14px;
    border-bottom:0.5px solid #DFDFDF;
    font-size:18px;
    color:#282828;
}
.dr-top table tr:last-child td {
    border-bottom:0.5px solid #A6A6A6;
}
.dr-bottom div.left {
    width:47%;
    float:left;
}
.dr-bottom div.right {
    width:47%;
    float:right;
}
.dr-bottom ul {
    border-bottom:0.5px solid #A6A6A6;
}
.dr-bottom ul li {
    padding:11px 0;
    font-size:14px;
    font-weight:normal;
    color:#282828;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    border-bottom:0.5px solid #DFDFDF;
    cursor: pointer;
}
.dr-bottom ul li:last-child {
    border-bottom:0;
}
#ad-contents:after, .dl-top ul:after, .da-subTit:after, .dl-bottom li:after, .dr-bottom div:after {
    content:"";
    display:block;
    clear:both;
}
.basic-table thead tr, .basic-table tbody tr {
    width:100%;
    vertical-align: middle;
}
.table-wrap {
    position:relative;
    height:auto;
    min-height:500px;
}
.ad-loading {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    max-height:300px;
    font-size:18px;
    color:#8D8D8D;
    text-align:center;
}
/*main-contents*/

/*error*/
.error, .basic-table td.error{
    color:#da2128;
}
/*error*/

/*report*/
.write-info {
    padding:16px;
    font-size:16px;
    color:#595959;
    border-top:2px solid #000;
    border-bottom:0.5px solid #DFDFDF;
}
.write-info span {
    display:inline-block;
    text-align: left;
    margin-right:20px;
}
.user-write {
    width:100%;
    padding:20px 16px;
    font-size:18px;
    line-height: 1.5;
    color:#595959;
}
.write-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.write-list .img-wrap {
    width:30%;
    overflow: hidden;
}
.write-list .text-wrap {
    width: 65%;
    margin-left:3%;
}
.text-wrap-bottom {
    padding:50px 0 60px;
    border-bottom: 1px solid #8D8D8D;
}
.text-wrap-bottom:last-child {
    border-bottom: none;
}
.text-wrap-bottom li h6 {
    font-size: 16px;
    color: #00934b;
    font-weight: 500;
    padding-bottom: 5px;
}
.text-wrap-bottom li:first-child .write-comment {
    margin-bottom: 18px;
}
.text-wrap-bottom li:first-child div {
    margin-bottom: 20px;
}
.write-list .text-wrap li:first-child {
    margin-bottom:26px;
}
.write-list .text-wrap h6 {
    font-size:15px;
    color:#00934b;
    font-weight:400;
    padding-bottom: 4px;
}
.write-list .text-wrap div {
    width:80%;
    font-size:18px;
    font-weight:normal;
    line-height:1.5;
    color:#000;
}
.modal .btn-wrap {
    margin-bottom: 0;
}
.btn-wrap {
    margin:50px auto 100px;
    text-align:center;
}
.board-btn {
    display:inline-block;
    width:130px;
    height:46px;
    border-radius: 4px;
    font-size:18px;
    font-weight:500;
    text-align: center;
}
.btn-edit {
    background:#fff;
    border:1px solid #00934b;
    color:#00934b;
}
.btn-cancel {
    background:#fff;
    border:1px solid #656565;
    color:#656565;
    margin-right:10px;
}
.report-btn {
    width: 80px;
    height: 36px;
    margin-left: 10px;
    background: #606060;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    border:none;
}
.complete {
    margin:0px 0 100px;
    font-size:18px;
    color:#000;
}
.e-line {
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    margin: 20px auto 30px;
}
.complete .left {
    float:left;
    margin-top: 7px;
    margin-right:50px;
    font-weight:bold;
}
.complete:after {
    content:"";
    display:block;
    clear:both;
}
.view-bottom{
    width: 100%;
    border-top: 1px solid #000000;
    table-layout: fixed;
}
.view-bottom tr{
    border-bottom: 1px solid #DFDFDF;
    line-height: 60px;
    font-size: 16px;
}
.view-bottom tr:nth-child(2) {
    border-bottom:1px solid #AAAAAA;
}
.view-bottom th{
    width: 7%;
    color: #000000;
    font-weight: normal;
    cursor: pointer;
    float: left;
}
.view-bottom th a{color: #000000;}
.view-bottom .view-off th, .view-bottom .view-off td{
    color:#afafaf;
}
.view-bottom tr:nth-child(1) span{
    width: 14px;
    height: 14px;
    display: inline-block;
    background: url("../../resources/admin/icon/up-arrow-on.svg") no-repeat center;
    background-size: cover;
    margin-right: 10px;
    vertical-align: middle;
}
.view-bottom tr:nth-child(2) span{
    width: 14px;
    height: 14px;
    display: inline-block;
    background: url("../../resources/admin/icon/down-arrow-on.svg") no-repeat center;
    background-size: cover;
    margin-right: 10px;
}
.view-bottom .view-off:nth-child(1) span{
    width: 14px;
    height: 14px;
    display: inline-block;
    background: url("../../resources/admin/icon/up-arrow-off.svg") no-repeat center;
    background-size: cover;
    margin-right: 10px;
    vertical-align: middle;
}
.view-bottom td{
    width: 93%;
    color: #333333;
    font-weight: normal;
    cursor: pointer;
    padding: 0 5% 0 0;
    float: left;
}
.view-bottom td div{
    width: 100%;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis;
    overflow: hidden;
}

.viewbtn-wrap {
    margin:30px auto 100px;
}
.view-btn {
    display:block;
    width:130px;
    height:46px;
    border-radius: 4px;
    border:1px solid #656565;
    font-size:18px;
    font-weight:500;
    color:#656565;
    text-align: center;
}
.view-btn.second {
    display: inline-block;
    margin-right:10px;
}
.view-btn.second:nth-child(2) {
    margin-right:0;
}
.viewbtn-wrap .left {
    float:left;
}
.viewbtn-wrap .left > * {
    display:inline-block;
    margin-right: 10px;
}
.viewbtn-wrap .right {
    float:right;
}
.viewbtn-wrap:after {
    content:"";
    display:block;
    clear:both;
}
/*report*/

.DraftEditor-root {
    padding: 10px 10px 30px !important;
    border-bottom: 1px solid #ccc !important;
}
/*
.basic-table .long-txt {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
 */
.basic-table .long-txt a {
    width: 700px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notice-container {
    padding:30px 16px;
    border-top:2px solid #000;
    border-bottom:2px solid #000000;
}
.notice-container > div:first-child {
    margin-bottom:10px;
}
.notice-container > div span, .notice-container > div div {
    width:200px;
    padding-top:14px;
    float: left;
    font-size:18px;
    color:#000000;
}
.notice-container > div div {
    width:80%;
}
.notice-container input, .notice-container textarea, .notice-container p {
    width:80%;
    float:left;
}
.notice-container textarea {
    min-height:250px;
}
.notice-container  input.input-co {
    height: 52px;
    line-height: 1;
    padding: 10px 14px;
    font-size: 18px;
    font-weight: 400;
    color: #333;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    box-sizing: border-box;
}
.notice-container textarea::placeholder, .notice-container p {
    font-size:18px;
    line-height:1.6;
}
{/*.notice-container p {
    padding-bottom:50px;
}*/}
.notice-container .line {
    display:block;
    width:100%;
    height:1px;
    background:#DDDDDD;
    margin:30px auto;
}
{/*.notice-content {
    display: block;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}*/}
.basic-table td.notice-go {
    text-align: center;
}
.notice-container .notice-attach p {
    padding-bottom:0;
}
.notice-container > div:after {
    content:"";
    display:block;
    clear:both;
}

.contents-title strong.green {
    margin-right:10px;
    font-weight: bold;
    color:#00934B;
}
.answer {
    margin-bottom:50px;
}
.answer li {
    width:98%;
    margin:10px auto;
    padding:30px 50px;
    border-radius:4px;
    background:#F9F9F9;
}
.answer-top {
    margin-bottom:10px;
}
.answer li strong {
    display:inline-block;
    font-size:14px;
    font-weight:bold;
    color:#00934B;
}
.b-line {
    display:inline-block;
    width:1px;
    height:10px;
    background:#DFDFDF;
    margin:0 10px;
}
.answer li small {
    font-size:12px;
    color:#595959;
}
.answer li p {
    font-size: 18px;
    line-height: 1.5;
    display: block;
    width: 100%;
    word-break: break-all;
}

.qna-comment strong {
    display: block;
    margin-bottom:10px;
    font-size:16px;
    color:#00934B;
    margin-top: 50px;
}
.ad.qna-comment {
    margin: 30px auto 50px;
    width: 98%;
    border-bottom: 0.5px solid #DFDFDF;
    margin-bottom: 50px;
}
.ad.qna-comment p, .text-wrap-bottom li div {
    font-size:18px;
    line-height: 1.4;
    color:#333;
}
.write-comment textarea {
    float:left;
    width:calc(100% - 150px);
    height:56px;
    min-height:56px;
    color:#000;
    font-size:18px;
}
.write-comment textarea::placeholder {
    font-size:18px;
}
.write-comment button {
    float:right;
    width:130px;
    height:56px;
    line-height:56px;
    border-radius: 4px;
    background:#00934B;
    font-size:18px;
    font-weight:bold;
    color:#fff;
    text-align: center;
}
.qna-btn {
    margin:auto;
}
.qna-btn:after, .write-comment:after {
    content:"";
    display: block;
    clear: both;
}
.ad-infoTit {
    display: block;
    width: 100%;
    font-size:20px;
    font-weight: bold;
    color: #000;
    padding-bottom: 10px;
    border-bottom: 2px solid #000;
}
.ad-infoTit span {
    float: left;
}
.ad-infoTit .enroll {
    float: right;
    width: 92px;
    height: 40px;
    background: #00934b;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
}
.ad-infoTit:after {
    content: "";
    display: block;
    clear: both;
}
.ad-com-info ul {
    margin-top:20px;
}
.ad-com-info ul li:nth-child(odd) {
    float: left;
    width:48%;
    margin-bottom: 20px;
}
.ad-com-info ul li:nth-child(even) {
    float: right;
    width:48%;
    margin-bottom: 20px;
}
.ad-com-info ul li > div, .ad-com-set > div > div {
    display: block;
    float: left;
    width: 100px;
    font-size: 18px;
    line-height: 52px;
}
.ad-com-info ul li > input {
    float: right;
    width: calc(100% - 120px);
}
.ad-com-info ul li:after, .ad-com-info ul:after {
    content:"";
    display: block;
    clear: both;
}
.ad-com-set, .ad-com-table {
    margin-top:100px;
}
.ad-com-set > div {
    margin-top: 20px;
}
.ad-com-set > div > select {
    float: left;
    width: 563px;
    height: 52px;
    margin-left: 20px;
    font-size: 18px;
    background:url("../../resources/admin/icon/droparrow.svg") no-repeat 94% 50%;
}
.ad-com-set > div > .d-search {
    height: 52px;
    line-height: 52px;
    background: #00934B;
    font-size: 18px;
}
.ad-com-set > div:after {
    content:"";
    display: block;
    clear: both;
}
.ad-com-table .basic-table {
    display: inline-table;
    border-top:none;
    margin:0 auto;
    min-height: initial;
}
.ad-com-table .basic-table span {
    display: block;
    width: 94%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.business-textarea {
    float: right;
    width: 68%;
    height: 300px;
    line-height: 1.2;
    padding: 10px 14px;
    font-size: 18px;
    font-weight: 400;
    color: #333;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    box-sizing: border-box;
}
.voucher {
    width: 92px;
    height: 40px;
    background: #00934b;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    float:right;
}

.list-table th.center, .list-table td.center {
    text-align:center;
}
.edit.activate button {
    border-color:#DA2128;
    background:#DA2128;
    color:#fff;
}
.edit.disabled button {
    border-color:#DFDFDF;
    background:#DFDFDF;
    color:#969696;
    cursor: default;
}

@media screen and (max-width:1480px) {
    #container {min-width:1480px;}
    #dashboard {min-width: 1340px;}
}
