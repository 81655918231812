/*footer*/
#footer {
    bottom: 0;
    width: 100%;
    background:#fff;
    border-top: 1px solid #ccc;
    z-index: -1;
}
.footer-in {
    position:relative;
    width:100%;
    max-width:1280px;
    margin:0 auto;
    padding:84px 0;
}
.f-logo {
    float:left;
    margin-right:95px;
}
.f-logo img {
    filter:grayscale(100%);
    -webkit-filter:grayscale(100%);
}
.f-utill {
    float:left;
}
.f-gnb {
    font-size: 0;
}
.f-gnb li {
    display:inline-block;
    padding-right:30px;
    font-size:15px;
    cursor: pointer;
}
.f-gnb li a {
    color:#000;
}
.f-info, .utills {
    margin:26px 0;
    font-size:14px;
    line-height:1.4;
    color:#605d5b;
}
.f-info .f-block {
    display:inline-block;
    width:26px;
}
.f-line {
    display:inline-block;
    margin:0 10px;
    width:1px;
    height:10px;
    background:#b7b7b7;
}
.utills {
    margin-bottom:0;
}
.f-copy {
    margin-top:12px;
    font-size:14px;
    color:#9b9b9b;
}
.f-app {
    float:right;
    font-size:0;
}
.qr-wrap, .app-down {
    display: inline-block;
    vertical-align: middle;
}
.app-down {
    margin-left:10px;
    font-size:14px;
    line-height:1.3;
    color:#605d5b;
}
.app-down strong {
    font-size:16px;
    color:#333;
}
.blog {
    display:block;
    margin-top:20px;
    width:90px;
    height:35px;
    line-height:36px;
    text-align:center;
    border-radius:18px;
    border:1px solid #8d8d8d;
    cursor: pointer;
}
.blog.mobile {
    display:none;
}
.go-top-hide {
    width: 80px;
    height:80px;
    border-radius: 100%;
    position: fixed;
    right: 5%;
    bottom: -18%;
    opacity: 0;
}
.go-top {
    width: 80px;
    height:80px;
    border-radius: 100%;
    position: fixed;
    right: 5%;
    bottom: 9%;
    cursor: pointer;
    z-index: 99;
    transition:all .5s;
    opacity: 1;
}
.go-top:hover {
    box-shadow: 0 9px 25px 0 rgba(0, 0, 0, 0.1);
}
.app:after, .clear {
    content:"";
    display:block;
    clear:both;
}
.down-btn, .faq-btn {
    position: fixed;
    right: 5%;
    bottom: 21%;
    display: block;
    padding-top:17px;
    width:80px;
    height:80px;
    line-height: 1.3;
    background: linear-gradient( 45deg, #4ece39, #00a856 );
    border-radius: 100%;
    font-size:16px;
    font-weight:bold;
    color:#fff;
    text-align: center;
    box-shadow: 0 0 0 rgba(244,154,25, 0.8);
    animation: pulse 2.3s infinite;
    -webkit-animation: pulse 2.3s infinite; /* Safari 4+ */
    -moz-animation:    pulse 2.3s infinite; /* Fx 5+ */
    -o-animation:      pulse 2.3s infinite; /* Opera 12+ */
    opacity: 1;
    transition:all .5s;
    z-index: 99;
}
.faq-btn {
    padding-top: 0px;
    line-height: 80px;
}
.down-btn-hide, .faq-btn-hide {
    opacity: 0;
    bottom: -22%;
}
.pulse:hover {
    animation: none;
}
@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 168, 86, 0.8);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 168, 86, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 168, 86, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 168, 86, 0.8);
        box-shadow: 0 0 0 0 rgba(0, 168, 86, 0.8);
    }
    70% {
        -moz-box-shadow: 0 0 0 13px rgba(0, 168, 86, 0);
        box-shadow: 0 0 0 13px rgba(0, 168, 86, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 168, 86, 0);
        box-shadow: 0 0 0 0 rgba(0, 168, 86, 0);
    }
}

/*footer*/

/*responsive*/
@media all and (max-width:1280px) {
    .footer-in {
        padding:5% 0;
    }
    .f-logo {
        float:none;
        margin-right:5%;
    }
    .f-utill {
        float:none;
        margin-top: 30px;
    }
    .f-app {
        margin-top: -154px;
    }
    .go-top {
        bottom:8%;
    }
}

@media all and (max-width:1024px) {
    .qr-wrap img {
        width:58px;
    }
    .app-down strong {
        font-size: 15px;
    }
    .app {
        display: none;
    }
    .f-logo {
        float:left;
    }
    .blog {
        display: none;
    }
    .blog.mobile {
        display:block;
        float:right;
        margin-top: 0;
    }
    .f-top-m:after {
        content:"";
        display: block;
        clear: both;
    }
    .f-info, .utills {
        margin: 20px 0;
    }
    .blog {
        line-height:33px;
    }
}

@media all and (max-width:640px) {
    .footer-in {
        padding: 10% 0;
    }
    .f-gnb li {
        font-size:14px;
        padding-right:14px;
    }
    .go-top {
        width: 60px;
        height: 60px;
    }
    .down-btn, .faq-btn {
        width: 60px;
        height: 60px;
        bottom:19%;
        padding-top: 14px;
        font-size:12px;
    }
    .faq-btn {
        line-height: 60px;
        padding-top: 0px;
    }
    .blog.mobile {
        margin-top: -8px;
    }
}

@media all and (max-width:480px) {
    .f-info, .utills {
        font-size: 13px;
    }
    .f-gnb li:nth-child(2) {
        margin-bottom: 10px;
    }
    .blog.mobile {
        margin-top:-6px;
    }
}
