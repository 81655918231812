#admin-login {
    position:relative;
    width: 100%;
    height:100vh;
    background:#f9f9f9;
}
.ad-loginIn {
    width: 480px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.ad-loginIn > h6 {
    font-size:20px;
    font-weight:500;
    color:#000;
    text-align: center;
    margin: 0 auto;
}
.ad-loginIn > .logo {
    margin:30px auto;
    text-align:center;
    float:none;
}
.input-wrap input:first-child {
    margin-bottom:8px;
}
.ad-loginIn > span {
    display: block;
    margin:8px auto 30px;
    font-size:14px;
    font-weight:400;
    color:#000;
    text-align: center;
}
.ad-loginIn > button {
    width:100%;
    height:62px;
    line-height:1;
    background:#00934B;
    font-size:20px;
    font-weight:600;
    text-align: center;
    color:#fff;
}